import React from "react";
import logo from './cm-logo.svg';
import downArrow from './down-arrow.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import validator from 'validator';
import './App.css';

function App() {

  const [first, setFirst] = React.useState("");
  const [last, setLast] = React.useState("");
  const [email, setEmail] = React.useState("");


  const handleFirst = (e) => {
    setFirst(e.target.value)
  }

  const handleLast = (e) => {
    setLast(e.target.value)
  }

  const handleEmail = (e) => {
    setEmail(e.target.value)
  }


  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
      <Container className="p-5" id="content">
        <a href="#content"><img src={downArrow} className="down-arrow" alt="down" /></a>
        <Row>
          <Col md={8}>
            <p className="blurb">Coral Mountain brings an immersive and refined adventure lifestyle to La Quinta and the Coachella Valley.</p>
            <p className="blurb">To learn more about Coral Mountain, please provide your name and email address and we will keep you updated.</p>
            <form action="https://front-desk.propertybase.com/forms/557461d9d02b6c9b2f207be6e19e1e5e248ed4cb" method="post" className="leadForm">
              <p><input type="text" name="contact[FirstName]" placeholder="First name" required onChange={handleFirst} value={first}/></p>
              <p><input type="text" name="contact[LastName]" placeholder="Last name" required onChange={handleLast} value={last}/></p>
              <p><input type="text" name="contact[Email]" placeholder="Email" required onChange={handleEmail} value={email}/></p>
              <p><input type="text" name=" contact[MobilePhone]" placeholder="Phone"/></p>
              <p><button type="submit" style={{ marginBottom: "50px" }} disabled={!first || !last || !email || !validator.isEmail(email)}>Submit</button></p>
              <input type="text" name="h_557461d9d02b6c9b2f207be6e19e1e5e248ed4cb" defaultValue="" className="hidden" />
              <input type="hidden" name="success_url" value="https://www.coralmountain.com/success" />
            </form>
          </Col>
          <Col md={4} className="contact">
            <h4>MEDIA INQUIRIES</h4>
            <p>Please contact Rachel Maher,<br/>
            REM Public Relations, at<br/>
            <a href="mailto:Rachel@rempublicrelations.com">Rachel@rempublicrelations.com</a></p>
          </Col>
        </Row>
      </Container>
      <Container className="p-5">
        <Row>
          <Col md={8}><span className="copy">© 2021 Meriwether Companies</span> <a href="https://www.meriwetherco.com">meriwetherco.com</a></Col>
        </Row>
      </Container>
    </div>
  );
}

export default App;
