import logo from '../cm-logo.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Success.css';

function Success() {
  return (
    <div className="App">
      <header className="App-header success">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Thank you for your interest in Coral Mountain.</p> 
        <p>Please stay tuned!</p>
      </header>
      <Container className="p-5 offset">
        <Row>
          <Col md={8}><span className="copy">© 2021 Meriwether Companies</span> <a href="https://www.meriwetherco.com">meriwetherco.com</a></Col>
        </Row>
      </Container>
    </div>
  );
}

export default Success;
