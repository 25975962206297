import logo from '../CM_Small_Horizontal_Logo_RGB_Sand.svg';
import banner from '../DJI_0645-estate-homesites-2400x800.jpg';
import masterplan from '../masterplan-labels-2400px.jpg';
import siteplan from '../estate-homesites-labels-2400.jpg';
import coralMountainModal from '../modal/coral-mountain-aerial-0665-2400x1350.jpg';
import boardwalkModal from '../modal/boardwalk-concept-2400x1350.jpg';
import theFarmModal from '../modal/the-farm-concept-2400x1350.jpg';
import theWaveModal from '../modal/the-wave-concept-2400x1350.jpg';
import theBodegaModal from '../modal/the-bodega-2400x1350.jpg';
import theGateModal from '../modal/entry-gate-2400x1350.jpg';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './Siteplan.css';
import MicroModal from 'react-micro-modal';

function Success() {
  const overides = {
    Dialog: {
      className: 'cm-dialog',
      style: { 
        zIndex: 150,
        padding: 0,
        border: 'none',
        maxWidth: 1076,
        backgroundColor: '#1C1F2A',
        borderRadius: 0
      },
    },
  };
  return (
    <div className="App">
      <header className="Siteplan-header success">
        <div className='content'>
          <div className='container'>
            <Row className='py-4'>
              <Col md={12}>
                <img src={logo} className="logo" alt="logo" />
              </Col>
            </Row>
          </div>
        </div>
        <img src={banner} className="banner" alt="banner" />
      </header>

      <main className='Siteplan'>
        <section className='plans'>
          <div className='content'>
            <Container className="py-5">
              <Row className='pb-4'>
                <Col md={6}>
                  <h2>CORAL MOUNTAIN MASTERPLAN</h2>
                  <p>Situated on a 400-acre site, the Coral Mountain community consists of up to 600 private residences, a boutique hotel with spa and fitness amenities, next-generation action sports — including a 16-acre surfing basin designed by the Kelly Slater Wave Company, commercial agriculture, restaurants, shops, and interpretive archeological elements. The Phase 1 offering of residences consists of approximately 145 properties.</p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className='siteplan-wrapper'>
                    <MicroModal 
                      overrides={overides}
                      trigger={(open) => <button className='label l1' onClick={open}>Coral Mountain</button>}>
                      {(close) => <div className='modal-content'>
                        <button onClick={close} className="close">&#10005;</button>
                        <img src={coralMountainModal} className="modal-image" alt="Coral Mountain" />
                        <div className='modal-text p-3'>The jagged slopes of Coral Mountain, in the foreground, set it apart from the Santa Rosa Mountains that form a backdrop to the west.</div>
                      </div>}
                    </MicroModal>

                    <a className='label l2 dark' href="#homesites">Estate Homesites</a>
                    
                    <MicroModal
                      overrides={overides}
                      trigger={(open) => <button className='label l3' onClick={open}>The Wave</button>}>
                      {(close) => <div className='modal-content'>
                        <button onClick={close} className="close">&#10005;</button>
                        <img src={theWaveModal} className="modal-image" alt="The Wave" />
                        <div className='modal-text p-3'>The 16-acre surfing basin, designed by the Kelly Slater Wave Company, sits at the base of Coral Mountain.</div>
                      </div>}
                    </MicroModal>

                    <MicroModal
                      overrides={overides}
                      trigger={(open) => <button className='label l4' onClick={open}>Village Core</button>}>
                      {(close) => <div className='modal-content'>
                        <button onClick={close} className="close">&#10005;</button>
                        <img src={boardwalkModal} className="modal-image" alt="The Boardwalk" />
                        <div className='modal-text p-3'>Conceptual sketch of the Village Boardwalk overlooking The Wave.</div>
                      </div>}
                    </MicroModal>

                    <button className='label l5 dead'>Outfitters</button>

                    <MicroModal
                      overrides={overides}
                      trigger={(open) => <button className='label l6' onClick={open}>Entry Gate</button>}>
                      {(close) => <div className='modal-content'>
                        <button onClick={close} className="close">&#10005;</button>
                        <img src={theGateModal} className="modal-image" alt="Entry" />
                        <div className='modal-text p-3'>The Entry Gate separates the public farms and markets from the private residences surrounding The Wave.</div>
                      </div>}
                    </MicroModal>

                    <MicroModal
                      overrides={overides}
                      trigger={(open) => <button className='label l7' onClick={open}>The Bodega</button>}>
                      {(close) => <div className='modal-content'>
                        <button onClick={close} className="close">&#10005;</button>
                        <img src={theBodegaModal} className="modal-image" alt="The Bodega" />
                        <div className='modal-text p-3'>Harvested food will be served at The Bodega, where residents can mix with the public at picnic tables.</div>
                      </div>}
                    </MicroModal>

                    <MicroModal
                      overrides={overides}
                      trigger={(open) => <button className='label l8' onClick={open}>The Farm</button>}>
                      {(close) => <div className='modal-content'>
                        <button onClick={close} className="close">&#10005;</button>
                        <img src={theFarmModal} className="modal-image" alt="The Farm" />
                        <div className='modal-text p-3'>Conceptual sketch of The Farm's fields. At the center is the public market building, The Bodega.</div>
                      </div>}
                    </MicroModal>
                    
                    <img src={masterplan} className="App-masterplan" alt="banner" />
                    </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className='sites' id="homesites">
          <div className='content'>
            <Container className="py-5">
              <Row className='pb-4'>
                <Col md={{ span: 8, offset: 2 }}>
                  <h2>ESTATE HOMESITES</h2>
                  <p>Surrounding a private lake and averaging 0.75 acres per property, Coral Mountain's 27 Estate Homesites afford epic views of Coral Mountain and the neighboring Santa Rosa Mountains. Estate Homesite owners can design and build a custom home, working with their own architect and builder to realize their unique vision. Ample setbacks provide privacy, yet residents are just moments away from the The Wave, Outfitter's (Coral Mountain's dynamic-sports center), wellness and spa amenities, and dining venues.</p>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                    <img src={siteplan} className="App-siteplan" alt="banner" />
                </Col>
              </Row>
            </Container>
          </div>
        </section>

        <section className='faq'>
          <div className='content'>
            <Container className="py-5">
              <Row className='pb-4'>
                <Col md={{ span: 8, offset: 2 }}>
                  <h2>FREQUENTLY ASKED QUESTIONS: ESTATE HOMESITES</h2>
                  
                  <div className='qa' id="qa1">
                      <h4>If I Purchase an Estate Homesite, do I have to commence construction in a certain timeframe?</h4>
                      <p>Unlike developer-built homes in the village core, there will not be an immediate "Compulsion to Build" clause for our Estate Homesites. Coral Mountain's community governance documents, including the CC&amp;R's and Design Guidelines, will provide further details on the building process and requirements.</p>
                  </div>

                  <div className='qa' id="qa2">
                    <h4>Can I hire my own architect, interior designer, and builder? And will I have to go through an architectural review?</h4>
                    <p>Yes, Estate Homesite owners can engage properly accredited and licensed architects, designers, and builders of their choosing.</p>
                    <p>Architectural plans will be subject to Design Guidelines to ensure that home designs meet Coral Mountain's standards for quality, sustainability, and the preservation of the surrounding areas. There will also be a review to ensure that the design does not negatively impact neighboring sites and owners.</p>
                  </div>

                  <div className='qa' id="qa3">
                      <h4>Can the 27 Estate Homes be entered into Coral Mountain's rental program?</h4>
                      <p>We are currently working with the City of La Quinta to define the nature of Coral Mountain's Short-Term Rental availability and restrictions. While we are striving to give every Coral Mountain owner the ability to rent out their property if they choose, we will not have the answer until the final entitlements are negotiated and secured.</p>
                  </div>

                  <div className='qa' id="qa4">
                      <h4>What is the delivery-and-closing timeline of the Estate Homesites compared to the rest of the offerings in Phase 1 at Coral Mountain?</h4>
                      <p>We anticipate that the Estate Homesites will be the first real estate to receive authorization from the State of California DRE (Department of Real Estate) to write non-contingent contracts and close escrow. As such, the Estate Homesites will be available for preview prior to other offerings at Coral Mountain. We will keep you informed as we progress through the real estate registration process.</p>
                  </div>

                  <div className='qa' id="qa5">
                      <h4>Do any of the Estate Homesites have views of The Wave?</h4>
                      <p>Yes. Depending on the site and the design plans for individual homes, we anticipate some of the Estate Homesites will have views of the surf basin from either ground-level or second-level spaces.</p>
                  </div>

                  <div className='qa' id="qa6">
                      <h4>How will prices be determined for Estate Homesites?</h4>
                      <p>Homesites will be priced based on an internal review of characteristics such as view, size, and location relative to amenities. To support pricing, Coral Mountain has engaged a third-party research group that will help assess fair value for the sites. Since many of our early buyers will also be investors in the project, it's important for us to be fair and transparent in our approach to pricing.</p>
                  </div>

                  <div className='qa' id="qa7">
                      <h4>Does a Wave membership come with an Estate Homesite?</h4>
                      <p>The availability of Wave Memberships will be dictated by the governing documents for the Club at Coral Mountain. Invitations to apply for membership at the Club are reserved for purchasers of real estate. The decision to buy a membership, and at what level, will be up to the individual homeowner.</p>
                      <p>Homeowners will have the opportunity to choose from a variety of membership types, including Wave Basin and Regular memberships. Estate Homesite purchasers will have an opportunity to review the Membership Plan and apply for a membership prior to closing on their homesite.</p>
                  </div>

                  <div className='qa' id="qa8">
                      <h4>Once the initial 27 Estate Homesites are sold, will there be other custom homesites to choose from?</h4>
                      <p>The Estate Homesites are the largest sites in Phase 1 of Coral Mountain and provide the most flexibility in terms of home size and architectural design features. In the coming weeks, we will detail the other homesite & built product opportunities at Coral Mountain, including Village Residences, Boardwalk Homes, and Icon and Semi-Custom Homesites (smaller home sites in the Village with some level of design flexibility.)</p>
                      <p>While no plans are set, it's possible that future phases of development at Coral Mountain will offer custom homesites similar to Estate Homesites.</p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <section className='footer'>
        <div className='content'>
            <Container className="py-5">
              <Row className='pb-4'>
                <Col md={{ span: 8, offset: 2 }}>
                  <span className="copy">© 2022 Meriwether Companies</span> <a href="https://www.meriwetherco.com">meriwetherco.com</a>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </main>
    </div>
  );
}

export default Success;
